<template>
  <div>
    <!-- 作者：黄楚斌 -->
    <myheader></myheader>
    <!-- 实训平台背景 -->
    <div class="header-background">
      <img src="../../assets/zykf.jpg" alt="" />
    </div>
    <!-- 标题们 -->
    <div class="nav">
      <div class="title" hidden>
        <!-- 标题们的真正的内容 -->
        <div style="display: flex; cursor: pointer" v-for="(tl, index) in titleList" :key="'a-' + index">
          <a class="title-all" :href="'#'+tl.type">{{ tl.type }}</a>
        </div>
      </div>
      <div class="black-line"></div>
    </div>
    <!-- 标题后的内容 -->
    <div id="平台概述" style="display: flex; justify-content: center">
      <!-- Header -->
      <div>
        <div class="fixed">
          <div class="box">
            <!-- 平台概述和蓝横线 -->
            <div class="wow animate__fadeInUp">
              <div class="title-type">方案简介</div>
              <div style="display: flex; justify-content: center" class="">
                <div class="blue-line"></div>
              </div>
            </div>
            <!-- 图片和文本 -->
            <div class="content">
              <div style="width:800px;margin-bottom: 20px; font-size: 16px;text-indent: 25px;
  letter-spacing: 2px;">公司专注于云计算、大数据、1+X云计算运维与开发、1+X Web前端开发的培训资源和教材开发，将现有资源整合，重新按照职业规划的角度开发模块化课程，力求打造“做中教、做中学”的理实一体化课堂。</div>
              <div class="wow animate__fadeInLeft"><img style="width: 700px; height: auto" src="../../assets/zykf1.jpg" alt="" /></div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="workshop-Direction">
                <div class="teaching-Case1 wow animate__fadeInUp" >
                    <div class="teaching-Title2"><span class="bigFont">4</span>大对接</div>
                    <div class="teaching-Title3">•	对接教育部国家教学标准</div>
                    <div class="teaching-Title3">•	对接教育部1+X证书标准
                    </div>
                    <div class="teaching-Title3">•	对接人社部职业技能标准
                    </div>
                    <div class="teaching-Title3">•	对接企业岗位技能标准
                    </div>
                </div>
                <div class="teaching-Case1 wow animate__fadeInUp" >
  
                    <div class="teaching-Title2"><span class="bigFont">24</span>本专业教材出版</div>
                    <div class="teaching-Title3">•	16本云计算专业教材</div>
                    <div class="teaching-Title3">•	5本大数据专业教材
                    </div>
                    <div class="teaching-Title3">•	2本选入省级教材
                    </div>
                    <div class="teaching-Title3">•	6本选入国家级教材
                    </div>
                    
                </div>
                <div class="teaching-Case1 wow animate__fadeInUp" >
                    <div class="teaching-Title2"><span class="bigFont">25</span>门云计算和1+X证书配套课程</div>
                    <div class="teaching-Title3">•	Docker容器技术与应用</div>
                    <div class="teaching-Title3">•	云计算平台运维与开发课程（初级）</div>
                    <div class="teaching-Title3">•	云计算平台运维与开发课程（中级）
                    </div>
                    <div class="teaching-Title3">•	云计算平台运维与开发课程（高级）
                    </div>
                    
                </div>
                <div class="teaching-Case1 wow animate__fadeInUp" >
                    <div class="teaching-Title2"><span class="bigFont">220</span>门模块化课程</div>
                    <div class="teaching-Title3">•	虚拟化与运维管理</div>
                    <div class="teaching-Title3">•	集群部署与开发基础</div>
                    <div class="teaching-Title3">•	私有云与容器云实践
                    </div>
                    <div class="teaching-Title3">•	公有云应用规划部署…
                    </div>
                    
                </div>
    </div>
    <div id="平台概述" style="display: flex; justify-content: center">
      <!-- Header -->
      <div>
        <div class="fixed">
          <div class="box2">
            <!-- 平台概述和蓝横线 -->
            <div class="wow animate__fadeInUp">
              <div class="title-type">特点优势</div>
              <div style="display: flex; justify-content: center" class="">
                <div class="blue-line"></div>
              </div>
            </div>
            <!-- 图片和文本 -->
            <div class="list_td">
              <div class="item_td">内容对接专业教学标准</div>
              <div  class="item_td">课程模块化体系设计</div>
              <div  class="item_td">企业真实项目案例转化</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div  style="display: flex; justify-content: center">
      <!-- Header -->
      <div>
        <div class="fixed">
          <div class="box1">
            <!-- 平台概述和蓝横线 -->
            <div class="wow animate__fadeInUp">
              <div class="title-type">应用场景</div>
              <div style="display: flex; justify-content: center" class="">
                <div class="blue-line"></div>
              </div>
            </div>
            <!-- 图片和文本 -->
            <div class="content">
              <div style="width:800px;margin: 20px;font-size: 16px;text-indent: 25px;
  letter-spacing: 2px;">公司联合各高职院校与高等教育出版社、人民邮电出版社、中国铁道出版社共同开发了云计算、大数据系列教材。</div>
              <div class="wow animate__fadeInLeft"><img style="width: 700px; height: auto" src="../../assets/zykf2.png" alt="" hidden/></div>
              <div style="width:800px;margin: 20px;font-size: 16px;text-indent: 25px;
  letter-spacing: 2px;">公司与山东商业职业技术学院合作完成了国家级云计算技术与应用专业教学资源库的企业真实项目案例的教学资源开发；与深圳职业技术学院、贵州轻工职业技术学院等众多高职院校合作完成了多个云计算、大数据、人工智能专业的省级、校级精品课程的建设。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center; background-color: #f9f9f9;" >
      <div class="userback wow animate__fadeInUp">
        <!-- 内容 -->
        <div style="margin-top: 30px; display: flex">
          
          <!-- 列表  -->
          <div class="list">
            <div v-for="(cj, index) in changjing1" :key="'a' + index">
              <div class="liebiao">
                <div style="margin-left: 20px">
                  <div style="font-size: 20px; font-weight: 900">{{ cj.identity }}</div>
                  <div>{{ cj.type }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右边图片 -->
          <div style="margin-left: 85px">
            <img src="../../assets/zykf4.png" alt="" style="width:500px"/>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center;">
      <div class="userback wow animate__fadeInUp">
        <!-- 内容 -->
        <div style="margin-top: 50px; display: flex">
          <!-- 左边图片 -->
          <div style="margin-left: 85px">
            <img src="../../assets/zykf5.png" alt="" style="width:480px;height: 280px;"/>
          </div>
          <!-- 列表  -->
          <div class="list">
            <div v-for="(cj, index) in changjing" :key="'a' + index">
              <div class="liebiao">
                <div style="margin-left: 20px">
                  <div style="font-size: 20px; font-weight: 900">{{ cj.identity }}</div>
                  <div>{{ cj.type }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <!-- ROUTER -->
    <mybottom></mybottom>

    <!-- BOTTOM -->
  </div>
</template>

<script>
import myheader from '@/components/Header.vue';
import mybottom from '@/components/Bottom.vue';

export default {
  components: { myheader, mybottom },
  data() {
    return {
      trainingMode: {
                name: '应用场景',
                content: '',
                url: require('../../../src/assets/ysj2.jpg'),
                url1: require('../../../src/assets/ys3.jpg'),
            },
            trainingMode1: {
                name: '应用场景',
                content: '',
                url: require('../../../src/assets/dsj2.jpg'),
                url1: require('../../../src/assets/dsj3.jpg'),
            },
            trainingMode2: {
                name: '应用场景',
                content: '',
                url: require('../../../src/assets/rgzn2.jpg'),
                url1: require('../../../src/assets/rgzn3.jpg'),
            },
      message: 'hello world',
      one: '',
      titleList: [
        {
          type: '平台概述',
          id: 1,
        },
        {
          type: '平台特色',
          id: 2,
        },
        {
          type: '应用场景',
          id: 3,
        }
      ],
      types:
        '',
      teses: [
        {
          url: require('@/assets/fangkuai.png'),
          type01: '教学平台一体设计',
          type02: '教学实训评测竞赛',
        },
        {
          url: require('@/assets/dianshi.png'),
          type01: '实训资源本地调用',
          type02: '支持院校本地调用实训资源',
        },
        {
          url: require('@/assets/tu03.png'),
          type01: '课程资源模块制作',
          type02: '对接【岗课赛证】标准',
        },
        {
          url: require('@/assets/yun04.png'),
          type01: '线上资源实时分发',
          type02: '公有云实时分发最新课程资源',
        },
      ],
      changjing: [
        {
          identity: '云计算相关系列精品课程',
          type: '深圳职业技术学院',
        },
        {
          identity: '',
          type: '•	云安全技术及应用',
        },
        {
          identity: '',
          type: '•	数据存储与数据管理',
        },{
          identity: '',
          type: '•	云计算综合运维',
        },{
          identity: '',
          type: '•	小程序设计与开发',
        },{
          identity: '',
          type: '•	资源总数：525',
        }
      ],
      changjing1: [
        {
          identity: '云计算国家资源库',
          type: '山东商业职业技术学院',
        },
        {
          identity: '',
          type: '•	云平台建设与运维类企业案例',
        },
        {
          identity: '',
          type: '•	云应用开发类企业案例',
        },{
          identity: '',
          type: '•	资源总数：2242',
        }
      ],
    };
  },
  mounted() {
    var wow = new this.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    });
    wow.init();
  },
  created() {},
  methods: {
    //  discoloration(){
    //    this.one= 'background-color: #2e71fe;'
    //  },
    //  godiscoloration(){
    //    this.one= ''
    //  }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to th is component only -->
<style scoped lang="less">
.workshop-Direction {
    /* margin-top:-10px; */
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    /*盒子会自动掉下来*/
}
.list_td{
  display: flex;
  justify-content: space-between;
  margin: 50px;
}
.item_td{
  background: #1c50b9;
  font-size: 15px;
  text-indent: 25px;
  letter-spacing: 2px;
  font-weight: 500;
  color: white;
  padding: 20px;
}
.teaching-Case1 {
    width: 270px;
    height: 400px;
    margin-left: 21px;
    margin-top: 30px;
    background-color: blaclk;
    box-shadow: 0px 0px 15px 1px rgb(226 222 222);
}
.teaching-Icon3 {
    text-align: center;
    margin-top: 30px;
    width: 100%;
    height: 50px;
}
.bigFont{
  font-size: 40px;
  color: #1c50b9;
}
.teaching-Title2 {
    text-align: center;
    margin-top: 50px;
    font-weight: 700;
    color: black;
    font-size: 16px;
}

.teaching-Title3 {
    text-align: center;
    margin: 20px;
    box-sizing: border-box;
    // line-height: 2;
    font-size: 16px;
}
.content-Box {
    margin-top: 50px;
}
.title1 {
    margin-left: auto;
    margin-right: auto;
    color: black;
    font-weight: 600;
    font-size: 30px;
    padding-top: 65px;
    text-align: center;
}
.content-box1 {
    width: 1200px;
    height: 280px;
    background-color: #ffffff;
    box-shadow: 0px 0px 3px 3px #e4dcdc;
    margin-top: 50px;
    display: flex;
}
.content-box2 {
    width: 830px;
    height: 100px;
    margin: auto;
    font-size: 16px;
    color: black;
    margin-top: 45px;
    justify-content: center;
    letter-spacing: 0.8px;
    line-height: 2;
}
.content-Box {
    margin-top: 30px;
}

.commonly {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top:20px ;
}

.commonly1 {
    margin-left: 75px;
    width: 300px;
    height: 200px;
}
.commonly1 img{
    width: 300px;
    height: 200px;
}
.commonly2 img{
    width: 300px;
    height: 200px;
}
.header-background {
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  min-width: 1200px;
  img {
    width: 100%;
  }
}
.nav {
  z-index: 999;
  position: sticky;
  top: 0px;
}
.title {
  height: 55px;
  display: flex;
  justify-content: center;
  background: #fff;
}
.black-line {
  width: 100%;
  max-width: 1920px;
  min-width: 1200px;
  height: 1px;
  background-color: black;
  opacity: 0.1;
  margin: auto;
}
.title-all {
  width: 180px;
  height: 57px;
  text-align: center;
  line-height: 57px;
  font-weight: 600;
  color: #747474;
}

.title-all:hover {
  color: #2e71fe;
  background-color: #f2f2f2;
}
.box {
  width: 1200px;
  height: 550px;
  padding-top: 60px;
}
.box1 {
  width: 1200px;
  height: 400px;
  padding-top: 60px;
}
.box2 {
  width: 1200px;
  height: 200px;
  padding-top: 60px;
}
.fixed {
  display: flex;
  justify-content: center;
  width: 1200px;
}
.title-type {
  display: flex;
  justify-content: center;
  font-weight: 800;
  font-size: 30px;
}
.blue-licontentne {
  width: 52px;
  height: 3px;
  background-color: #6697fe;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top:20px ;
}
.content-type {
  margin-left: 90px;
  line-height: 28px;
  width: 600px;
  font-size: 15px;
  text-indent: 25px;
  letter-spacing: 2px;
  font-weight: 500;
}
.characteristic {
  // width: 1920px;
  height: 550px;
  background-color: #f9f9f9;
  padding-top: 70px;
}
.tese-box {
  margin-right: 30px;
  display: flex;
  text-align: center;
}
.xiao-box {
  padding-top: 36px;
  text-align: center;
  width: 280px;
  height: 270px;
  background-color: #ffffff;
}
.xiao-box:hover .img-blue-line {
  background-color: blue;
}
.img-blue-line {
  width: 60px;
  height: 4px;
  background-color: #ffffff;
  margin-left: 110px;
  margin-top: -36px;
  margin-bottom: 36px;
  // text-align: center;
}
.tese-name {
  margin-top: 35px;
  font-size: 20px;
  font-weight: 1000;
}
.tese-type {
  margin-top: 20px;
  font-size: 15px;
}
.userback {
  height: 350px;
}
.list{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.liebiao {
  display: flex;
  width: 460px;
  margin-left: 130px;
  margin-bottom: 20px;
  align-items: center;
}
.zaixian {
  margin-top: 47px;
  background-color: #1c50b9;
  background-image: url(../../assets/zaixiantiyan.png);
  height: 340px;
  width: auto;
  display: flex;
  justify-content: center;
  padding-top: 65px;
  // flex-direction: row-reverse;
}
.zaixian-type {
  text-align: center;
  font-size: 30px;
  color: white;
}
.white-line {
  margin-top: 15px;
  width: 50px;
}
.zaixian-type02 {
  font-size: 17px;
  margin-top: 30px;
  color: white;
  width: auto;
}
.zaixian-anniu {
  margin-top: 50px;
  border-radius: 20px;
  background-color: #ffffff;
  width: 230px;
  margin-left: 40px;
  height: 45px;
  color: #2e71fe;
  font-size: 20px;
  font-weight: 550;
  line-height: 45px;
  text-align: center;
  // background-image: url(../../assets/yuanju01.png);
}
</style>
